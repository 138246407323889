<template>
  <div>
    <table-list :columns="columns"
                :data="ledgerList"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <template #blockHeight="{row}">
        <span v-if="row.blockHeight">{{toThousands(row.blockHeight)}}</span>
        <span v-else>无</span>
      </template>
      <!-- 哈希算法 -->
      <template #hashAlgorithm="{row}">
        <span v-if="row.hashAlgorithm===201">sha3</span>
        <span v-else-if="row.hashAlgorithm===202">sm3</span>
      </template>
      <!-- 账本哈希 -->
      <template #hash="{row}">

        <div class="div-row"
             v-if="row.hash">
          <popover :width="500"
                   :shard="row.shardName"
                   :value="row.hash"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy copy-icon"
             @click="copy($event, row.hash)"></i>
        </div>
        <span v-else-if="!row.hash">无</span>
      </template>
      <!-- 创建地址算法 -->
      <template #sendAlgorithm="{row}">
        <span v-if="row.sendAlgorithm===101">rsa</span>
        <span v-else-if="row.sendAlgorithm===102">sm2</span>
      </template>

      <!-- 创建地址 -->
      <template #sendAddress="{row}">
        <div class="div-row"
             v-if="row.sendAddress">
          <popover :width="500"
                   :shard="row.shardName"
                   :value="row.sendAddress"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy copy-icon"
             @click="copy($event, row.sendAddress)"></i>
        </div>
        <span v-else-if="!row.sendAddress">无</span>
      </template>
      <!-- 验证地址算法 -->
      <template #verifyAlgorithm="{row}">
        <span v-if="row.verifyAlgorithm===101">rsa</span>
        <span v-else-if="row.verifyAlgorithm===102">sm2</span>
      </template>
      <!-- 验证地址 -->
      <template #verifyAddress="{row}">
        <span class="table-row"
              v-if="row.verifyAddress&&row.verifyAddress.length>20"
              @click="handleOpen(row.verifyAddress,1)">...{{ row.verifyAddress.substr(row.verifyAddress.length-26,row.verifyAddress.length-1) }}</span>
        <span v-else-if="!row.verifyAddress">无</span>
        <span class="shenglue"
              v-else>{{row.verifyAddress}}</span>
      </template>
      <!-- 时间戳 -->
      <template #timestamp="{row}">
        {{ tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
      <!-- 共识算法 -->
      <template #promiseCode="{row}">
        <span v-if="row.promiseCode===101">PoL</span>
        <span v-else-if="row.promiseCode===201">PoS</span>
        <span v-else-if="row.promiseCode===301">Paxos</span>
      </template>
      <!-- 创建地址签名 -->
      <template #sendSign="{row}">
        <span v-if="row.sendSign&&row.sendSign.length>20">...{{ row.sendSign.substr(row.sendSign.length-26,row.sendSign.length-1) }}</span>
        <span v-else-if="!row.sendSign">无</span>
        <span class="shenglue"
              v-else>{{row.sendSign}}</span>
      </template>
      <!-- 验证地址签名 -->
      <template #verifySign="{row}">
        <span v-if="row.verifySign&&row.verifySign.length>20">...{{ row.verifySign.substr(row.verifySign.length-26,row.verifySign.length-1) }}</span>
        <span v-else-if="!row.verifySign">无</span>
        <span class="shenglue"
              v-else>{{row.verifySign}}</span>
      </template>
      <!-- 执行结果 -->
      <template #result="{row}">
        <span v-if="row.result">成功</span>
        <span v-else-if="!row.result">失败</span>
      </template>
      <!-- 接收者地址 -->
      <template #receiveAddress="{row}">
        <span class="table-row"
              v-if="row.receiveAddress&&row.receiveAddress.length>20"
              @click="handleOpen(row.receiveAddress,1)">...{{ row.receiveAddress.substr(row.receiveAddress.length-26,row.receiveAddress.length-1) }}</span>
        <span v-else-if="!row.receiveAddress">无</span>
        <span class="shenglue"
              v-else>{{row.receiveAddress}}</span>
      </template>
      <!-- 记账单位哈希 -->
      <template #token="{row}">
        <span class="table-row"
              v-if="row.token&&row.token.length>20"
              @click="handleOpen(row.token,2)">...{{ row.token.substr(row.token.length-26,row.token.length-1) }}</span>
        <span v-else-if="!row.token">无</span>
        <span class="shenglue"
              v-else>{{row.token}}</span>
      </template>
      <!-- 拥有者 -->
      <template #owner="{row}">
        <span class="table-row"
              v-if="row.owner&&row.owner.length>20"
              @click="handleOpen(row.owner,2)">...{{ row.owner.substr(row.owner.length-26,row.owner.length-1) }}</span>
        <span v-else-if="!row.owner">无</span>
        <span class="shenglue"
              v-else>{{row.owner}}</span>
      </template>
      <!-- 是否销毁、质押、赎回 -->
      <template #reCall="{row}">
        <span v-if="row.reCall">是</span>
        <span v-else-if="!row.reCall">否</span>
      </template>
      <!-- 赎回时间 -->
      <template #redemptionTimestamp="{row}">
        {{ tf(row.redemptionTimestamp, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
      <!-- 赎回哈希 -->
      <template #reHash="{row}">
        <span class="table-row"
              v-if="row.reHash&&row.reHash.length>20"
              @click="handleOpen(row.reHash,2)">...{{ row.reHash.substr(row.reHash.length-26,row.reHash.length-1) }}</span>
        <span v-else-if="!row.reHash">无</span>
        <span class="shenglue"
              v-else>{{row.reHash}}</span>
      </template>
      <!-- 质押哈希 -->
      <template #depositHash="{row}">
        <span class="table-row"
              v-if="row.depositHash&&row.depositHash.length>20"
              @click="handleOpen(row.depositHash,2)">...{{ row.depositHash.substr(row.depositHash.length-26,row.depositHash.length-1) }}</span>
        <span v-else-if="!row.depositHash">无</span>
        <span class="shenglue"
              v-else>{{row.depositHash}}</span>
      </template>
      <!-- 父存证哈希 -->
      <template #parentHash="{row}">
        <span class="table-row"
              v-if="row.parentHash&&row.parentHash.length>20"
              @click="handleOpen(row.parentHash,2)">...{{ row.parentHash.substr(row.parentHash.length-26,row.parentHash.length-1) }}</span>
        <span v-else-if="!row.parentHash">无</span>
        <span class="shenglue"
              v-else>{{row.parentHash}}</span>
      </template>
      <!-- 数据来源 -->
      <template #dataSources="{row}">
        <span class="shenglue"
              v-if="row.dataSources">{{ row.dataSources}}</span>
        <span v-else>无</span>
      </template>
      <!-- 最新流转记录 -->
      <template #txHash="{row}">
        <span class="table-row"
              v-if="row.txHash&&row.txHash.length>20"
              @click="handleOpen(row.txHash,2)">...{{ row.txHash.substr(row.txHash.length-26,row.txHash.length-1) }}</span>
        <span v-else-if="!row.txHash">无</span>
        <span class="shenglue"
              v-else>{{row.txHash}}</span>
      </template>
      <!-- 销毁记录 -->
      <template #daHash="{row}">
        <span class="table-row"
              v-if="row.daHash&&row.daHash.length>20"
              @click="handleOpen(row.daHash,2)">...{{ row.daHash.substr(row.daHash.length-26,row.daHash.length-1) }}</span>
        <span v-else-if="!row.daHash">无</span>
        <span class="shenglue"
              v-else>{{row.daHash}}</span>
      </template>
      <!-- 状态索引 -->
      <template #statusKey="{row}">
        <span v-if="row.statusKey">...{{ row.statusKey.substr(row.statusKey.length-26,row.statusKey.length-1) }}</span>
        <span v-else>无</span>
      </template>
      <!-- 状态数据 -->
      <template #json="{row}">
        <span class="table-row shenglue"
              v-if="row.json"
              @click="handleOpen(row.json,3)">{{ row.json }}</span>
        <span v-else>无</span>
      </template>
      <!-- 存证数据 -->
      <template #message="{row}">
        <span class="table-row shenglue"
              v-if="row.message"
              @click="handleOpen(row.message,4)">{{ row.message }}</span>
        <span v-else>无</span>
      </template>
      <!-- 备注 -->
      <template #remark="{row}">
        <popover v-if="row.remark"
                 :width="500"
                 :shard="row.shardName"
                 :value="row.remark"
                 :divClass="'shenglue-1'"
                 :isSubStr="false"></popover>
        <div v-else>无</div>
      </template>
      <!-- 合约代码 -->
      <template #contract="{row}">
        <el-button v-if="row.contract"
                   type="text"
                   icon="ym-icon-liulan"
                   @click="handleContract(row.contract)"> 查看</el-button>
        <span v-else>无</span>
      </template>

      <!-- 查看详情 -->
      <!-- <template #row="{row}">
        <el-button type="text"
                   @click="handleDetail(row.hash)">查看</el-button>
      </template> -->

    </table-list>
    <!-- Dialog -->
    <el-dialog :title="title"
               width="55%"
               :visible.sync="dialogVisible">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import Popover from '@/components/Popover'
import TableList from '@/components/TableList'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
import publicFunction from '@/utils/publicFunction.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        // 合并表格头
        this.getTableHead(this.datas.ledgerType)
        // 清空数据
        this.ledgerList = []
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      loading: false,
      dialogVisible: false, // 弹窗
      title: '',
      info: '',
      ledgerList: [],
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: [],
      headData: tableObj.headData
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 跳转详情页
    handleDetail(hash) {
      // console.log(this.$route.path)
      if (this.$route.path === '/DataPlatform/ledger') {
        this.$router.push({
          name: 'ledgerDetails',
          query: {
            searchKey: hash
          }
        })
      } else {
        this.$router.push({
          name: 'chainLedgerDetails',
          query: {
            searchKey: hash
          }
        })
      }
    },
    // 合并表格头
    getTableHead(value) {
      this.columns = tableObj.tableData
      // 合并表格头
      // if (value === 1000) {
      //   this.columns = tableObj.tableData
      // }
      // if (value === 101) {
      //   this.columns = tableObj.tableData.concat(tableObj.token)
      // }
      // if (value === 102) {
      //   this.columns = tableObj.tableData.concat(tableObj.transaction)
      // }
      // if (value === 103) {
      //   this.columns = tableObj.tableData.concat(tableObj.chainData)
      // }
      // if (value === 104) {
      //   this.columns = tableObj.tableData.concat(tableObj.deposit)
      // }
      // if (value === 105) {
      //   this.columns = tableObj.tableData.concat(tableObj.redeem)
      // }
      // if (value === 106) {
      //   this.columns = tableObj.tableData.concat(tableObj.tokenNFT)
      // }
      // if (value === 107) {
      //   this.columns = tableObj.tableData.concat(tableObj.transactionNFT)
      // }
      // if (value === 108) {
      //   this.columns = tableObj.tableData.concat(tableObj.statusData)
      // }
      // if (value === 201) {
      //   this.columns = tableObj.tableData.concat(tableObj.contract)
      // }
      // if (value === 1) {
      //   this.columns = tableObj.tableData.concat(tableObj.nftDamage)
      // }
    },
    // 查看详细信息
    // async handleOpen(value, type) {
    //   console.log(value, type)
    //   if (type === 1) {
    //     this.title = '账户信息'
    //     let option = {
    //       shard: this.datas.shard,
    //       address: value
    //     }
    //     const { data } = await this.$api.chainQL.accountInfo(option)
    //     if (data.listAccount.account.length !== 0) {
    //       this.info = this.syntaxHighlight(data.listAccount.account[0])
    //       this.dialogVisible = true
    //     } else {
    //       this.$message.error('暂无账户信息！')
    //     }
    //   } else if (type === 2) {
    //     this.title = '账本信息'
    //     let option = {
    //       shard: this.datas.shard,
    //       hash: value
    //     }
    //     const { data } = await this.$api.chainQL.ledgerInfo(option)
    //     if (data.listLedger.ledger.length !== 0) {
    //       this.info = this.syntaxHighlight(data.listLedger.ledger[0])
    //       this.dialogVisible = true
    //     } else {
    //       this.$message.error('暂无账本信息！')
    //     }
    //   } else if (type === 3) {
    //     this.title = '状态数据'
    //     this.info = value
    //     this.dialogVisible = true
    //   } else if (type === 4) {
    //     this.title = '存证数据'
    //     this.info = value
    //     this.dialogVisible = true
    //   }
    // },
    handleContract(value) {
      this.title = '合约代码'
      this.info = value
      this.dialogVisible = true
    },
    syntaxHighlight(_json) {
      var json = {}
      if (typeof _json !== 'string') {
        for (let item in _json) {
          var value = _json[item]
          if (value == null || value === undefined) {
            continue
          }
          json[item] = value
        }
        json = JSON.stringify(json, undefined, 2)
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
      return json.replace(
        // eslint-disable-next-line no-useless-escape
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 判断是否包含中文
    hasChinese(value) {
      // var reg = new RegExp(/.*[/u4e00-/u9fa5]+.*$/)
      if (escape(value).indexOf('%u') < 0) {
        return false // 不包含中文
      } else {
        return true // 包含中文
      }
    },
    async getData(currentPage) {
      if (currentPage === 1) {
        this.loading = true
        let option = {
          page: 0,
          limit: this.pageData.size,
          shard: this.datas.shard,
          blockHeight: this.datas.blockHeight || null,
          hash: this.datas.hash || null,
          sendAddress: this.datas.sendAddress || null,
          startTime: Date.parse(this.datas.timeList[0]) || null,
          endTime: Date.parse(this.datas.timeList[1]) || null,
          ledgerType: this.datas.ledgerType
        }
        let { data } = await this.$api.chainQL.tableLedger(option)
        this.pageData.total = data.listLedger.total
        this.ledgerList = data.listLedger.ledger
      } else if (currentPage > 1) {
        this.loading = true
        let option = {
          page: this.pageData.size * (currentPage - 1),
          limit: this.pageData.size,
          shard: this.datas.shard,
          blockHeight: this.datas.blockHeight || null,
          hash: this.datas.hash || null,
          sendAddress: this.datas.sendAddress || null,
          startTime: Date.parse(this.datas.timeList[0]) || null,
          endTime: Date.parse(this.datas.timeList[1]) || null,
          ledgerType: this.datas.ledgerType || null
        }
        let { data } = await this.$api.chainQL.tableLedger(option)
        if (!data.listLedger) {
          this.loading = false
          this.$message.error('搜索引擎暂时查不到数据，过一会再查！')
        } else {
          this.pageData.total = data.listLedger.total
          this.ledgerList = data.listLedger.ledger
        }
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.copy-icon {
  margin-left: 10px;
}
// js样式
::v-deep pre {
  min-height: 400px;
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
::v-deep pre::-webkit-scrollbar {
  height: 10px;
}
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
